<template>
  <a-table
    :columns="columns"
    :data-source="dataSource"
    :pagination="{ pageSize: 10, showTotal: total => `共 ${total} 条`, total: dataSource.length }"
    row-key="id"
    :scroll="{ x: scrollX }"
    class="custom-table"
  >
    <template slot="name" slot-scope="record">
      <a-tooltip placement="topLeft" :title="record">{{ record }}</a-tooltip>
    </template>
    <template slot="status" slot-scope="text">
      <source-status-tag :status="text"></source-status-tag>
    </template>
  </a-table>
</template>

<script>
import SourceStatusTag from '@/components/tag/SourceStatusTag'

export default {
  name: 'VolumeSimpleTable',
  components: {
    SourceStatusTag
  },
  props: {
    dataSource: {
      type: Array
    }
  },
  data () {
    return {
      columns: [
        {
          dataIndex: 'name',
          title: '名称',
          fixed: 'left',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'status',
          align: 'center',
          title: '状态',
          width: 100,
          scopedSlots: {
            customRender: 'status'
          }
        }
      ]
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => {
        sum += column.width
      })
      return sum
    }
  }
}
</script>
